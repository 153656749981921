import { Container } from 'react-awesome-styled-grid'
import React, { } from 'react'
import { Seo } from 'components/helpers'
import { Block, Title, Text, Button, Vector } from '@superlogica/design-ui'

interface ViewProps {
    mensagem: string
}

const Sucess = ({ mensagem }: ViewProps) => { 
console.log(mensagem)
  return (
    <Container>
        <Seo title="Energia" />
      <Block tag="section" padding={{ xxxs: '10px 0', sm: '10px 0' }}>
        <Block
          tag="article"
          marginTop={24}
          maxWidth={500}
          marginLeft="auto"
          marginRight="auto"
          textAlign="center"
        >
             <Title
            tag="h3"
            size="display03"
            font="heading"
            weight="bold"
            color="charmander500"
            textAlign="center"
            marginBottom={24}
          >
            Olá, tudo bem?
          </Title>

          <Block>
            <Text
              textAlign="center"
              marginBottom={40}
              dangerouslySetInnerHTML={{
                __html: mensagem ?? ''
              }}
            />
          </Block>
          <Button
            tag="a"
            href="https://energia.superlogica.com/"
            title="Voltar para o site"
            weight="semiBold"
            leftIcon={<Vector name="arrow-left-animation" />}
          >
            Voltar para o site
          </Button>
        </Block>
      </Block>
    </Container>
  )
}

export default Sucess
