import React from 'react'
import { Text, Title } from '@superlogica/design-ui'
import * as S from './Heading.styled'

interface HeadingProps {
  label?: string
  title?: string
}

const Heading = ({ label, title }: HeadingProps) => (
  <S.Wrapper>
    {!!label && (
      <Text
        size="label02"
        weight="bold"
        letterSpacing={1}
        color="charmander500"
        marginBottom={8}
        uppercase
      >
        {label}
      </Text>
    )}
    <Title
      tag="h1"
      size="display02"
      font="heading"
      color="babar900"
      textAlign="center"
    >
      Energia
    </Title>
    {!!title && (
      <Text
        marginTop={40}
        textAlign="center"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    )}
  </S.Wrapper>
)

export default Heading
