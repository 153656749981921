import { FormField } from '@superlogica/design-ui'

const templates: Record<string, FormField[]> = {
  0: [
    {
      field: 'accept',
      name: 'accept_privacy_terms',
      value: true,
      children: 'Li e aceito',
      url: {
        href: 'https://energia.superlogica.com/arquivos/termo_comparta_adesao.pdf',
        target: '_blank',
        rel: 'nofollow noreferrer',
        label: 'os termos de Adesão ao Consórcio Comparta Energia'
      },
      validations: {
        type: 'boolean',
        enum: [true]
      }
    },
    {
      field: 'button',
      className: 'arrow-animation arrow-right-animation',
      weight: 'bold',
      type: 'submit',
      label: 'Aceitar Termo',
      fluid: true
    }
  ],
  1: [
    {
      field: 'accept',
      name: 'accept_privacy_terms',
      value: true,
      children: 'Li e aceito',
      url: {
        href: 'https://energia.superlogica.com/arquivos/termo-de-adesao-omega-sp.pdf',
        target: '_blank',
        rel: 'nofollow noreferrer',
        label: 'os termos de Adesão ao Omega'
      },
      validations: {
        type: 'boolean',
        enum: [true]
      }
    },
    {
      field: 'button',
      className: 'arrow-animation arrow-right-animation',
      weight: 'bold',
      type: 'submit',
      label: 'Aceitar Termo',
      fluid: true
    }
  ]
}
export default templates
