import React from 'react'
import { PageProps } from 'gatsby'

import View from 'views/termo'
import NotFoundPage from './404'

const IndexPage: React.FC<PageProps> = ({ location }) => {
 
  const params = new URLSearchParams(location.search)
  const condId = params.get('condId') || ''

  return (
    
    <>
   {condId ?  
    <View condId={condId} />
    :
    <NotFoundPage />}
   
    </>
  )
}

export default IndexPage
