import { Block, Text, Title, Spinner, Form } from '@superlogica/design-ui'
import Heading from 'components/app/Heading'
import { Seo } from 'components/helpers'
import { showToast } from '@superlogica/design-system'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'
import templates from './template'

import axios from 'axios'
import Sucess from './sucess'

interface Data {
  termoUso: string
  enderecos: string
  cooperativa: string
}

interface ViewProps {
  condId: string
}

const View = ({ condId }: ViewProps) => {
  const baseURL = 'https://api.energia.superlogica.com/backoffice/v1/contratos/'
  const [data, setData] = useState<Data>({ termoUso: '', enderecos: '', cooperativa: '' })
  const [isLoading, setLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [mensagem, setMensagem] = useState('')

  var concessionaria = 0

  if(data.cooperativa === "Consórcio Omega GD 1"){
    concessionaria = 1
  }
  useEffect(() => {
    if (!condId) return

    axios
      .get(baseURL + 'get/InfosCliente', { params: { condId } })
      .then((response) => {
        setLoading(false)
        setData(response.data)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 400) {
          showToast({
            type: 'error',
            message:
              'Ocorreu um erro.Entre em contato com nossa equipe de suporte para obter mais informações'
          })
          setIsError(true)
          setMensagem(
            'Você não pode aceitar os termos ainda. Entre em contato com nossa equipe de suporte para obter mais informações'
          )
        } else {
          showToast({
            type: 'error',
            message:
              'Ocorreu um erro.Entre em contato com nossa equipe de suporte para obter mais informações'
          })
          setIsError(true)
          setMensagem(
            'Ocorreu um erro.Entre em contato com nossa equipe de suporte para obter mais informações'
          )
        }
      })
  }, [])

  const onSubmit = () => {
    axios
      .patch(baseURL + 'update/novosTermos?condId=' + condId)
      .then((response) => {
        showToast({
          type: 'success',
          message: response.data.message
        })
        setIsError(true)
        setMensagem(
          'Os novos termos foram aceitos com sucesso! <br>' +
            'Se tiver alguma dúvida ou precisar de ajuda, não hesite em entrar em contato conosco. Nossa equipe de suporte está pronta para ajudá-lo a qualquer momento.'
        )
      })
      .catch((error) => {
        setLoading(false)
        showToast({
          type: 'error',
          message:
            error.message +
            'Ocorreu um erro. Entre em contato com nossa equipe de suporte para obter mais informações'
        })
        setIsError(true)
        setMensagem(
          'Ocorreu um erro.Entre em contato com nossa equipe de suporte para obter mais informações'
        )
      })
  }
  return (
    <Block tag="section">
      <Container>
        <Block
          tag="article"
          marginTop={24}
          maxWidth={520}
          marginLeft="auto"
          marginRight="auto"
        >
          {isLoading ? (
            <Block tag="section" padding={{ xxxs: '40px 0', sm: '20px 0' }}>
              <Row justify="center">
                <Col xs={12} sm={10} md={8} lg={6}>
                  <Heading
                    label="Detalhes"
                    title="Aguarde.. <br> Estamos trazendo seus dados"
                  />
                </Col>
              </Row>
              <Block display={'flex'} justifyContent={'center'}>
                <Spinner color="sonic500" size={32} />
              </Block>
            </Block>
          ) : isError ? (
            <>
              <Row justify="center">
                <Col xs={12} sm={10} md={8} lg={6}>
                  <Heading label="Aceite" />
                </Col>
              </Row>
              <Sucess mensagem={mensagem} />
            </>
          ) : (
            <>
              <Seo title="Energia" />
              <Row justify="center">
                <Col xs={12} sm={10} md={8} lg={6}>
                  <Heading label="Aceite" />
                </Col>
              </Row>
              <Title
                tag="h3"
                size="display03"
                font="heading"
                weight="bold"
                color="charmander500"
                textAlign="center"
                marginBottom={32}
              >
                Novo Termo de adesão
              </Title>
              <Text
                textAlign="left"
                marginBottom={40}
                dangerouslySetInnerHTML={{ __html: data.termoUso ?? '' }}
              />
              <Title
                tag="h3"
                size="display04"
                color="babar900"
                marginBottom={24}
                weight="bold"
              >
                Unidades selecionadas:
              </Title>
              <Block
                display="flex"
                flexDirection="column"
                gap={20}
                marginBottom={32}
              >
                <Block
                  borderRadius={4}
                  bgColor="babar0"
                  border="1px solid"
                  borderColor="babar200"
                  padding={20}
                >
                  <Text size="body01">{data.enderecos}</Text>
                </Block>
              </Block>

              <Text size="body02" marginBottom={20}>
                Aceite o(s) termo(s) abaixo:
              </Text>
              <Form onSubmit={onSubmit} template={templates[concessionaria]}  />
            </>
          )}
        </Block>
      </Container>
    </Block>
  )
}

export default View
